<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-row class="no-gutters">
        <b-col>
          <h1 class="mr-sm-4 header-tablepage">RICH MENU DETAIL</h1>
        </b-col>
      </b-row>

      <b-tabs v-model="tabIndex">
        <b-tab title="Set up">
          <div class="bg-white">
            <div class="title-tabs">
              <b-row calss="">
                <b-col md="6" class="text-left d-flex">
                  <div>General Information</div>
                </b-col>
              </b-row>
            </div>
            <div class="p-3">
              <b-row>
                <b-col md="6">
                  <InputText
                    v-model="form.name"
                    isRequired
                    textFloat="Name"
                    placeholder="Name"
                    type="text"
                    :isValidate="$v.form.name.$error"
                    :v="$v.form.name"
                    :disabled="id != 0"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    v-model="form.richMenuAlias"
                    textFloat="Alias"
                    placeholder="Alias"
                    type="text"
                    :isValidate="$v.form.richMenuAlias.$error"
                    :v="$v.form.richMenuAlias"
                    :disabled="id != 0"
                  />
                </b-col>

                <b-col>
                  <b-form-checkbox
                    :id="`checkbox-default`"
                    :name="`checkbox-default`"
                    v-model="form.isDefault"
                    :disabled="id != 0"
                  >
                    Default
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>

            <Template ref="template" :data="form" :id="id"></Template>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <FooterAction
      routePath="/setting/rich-menu"
      @submit="saveForm()"
      :hideSubmit="id != 0"
    />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputText from "@/components/inputs/InputText.vue";
import Template from "./components/Template.vue";
import { required } from "vuelidate/lib/validators";
const eng_number = (val) => {
  if (!val) return true;
  if (val.match("[a-z\s0-9-]+$")) {
    return true;
  }
  return false;
};
export default {
  components: {
    OtherLoading,
    InputText,
    Template,
  },

  data() {
    return {
      isLoading: true,
      form: {
        name: "",
        richMenuAlias: "",
        isDefault: false,
      },
      id: this.$route.params.id,
      tabIndex: 0,
      templateOptions: [{ text: "3*2", value: "3*2" }],
    };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        richMenuAlias: {
          eng_number,
        },
      },
    };
  },
  async created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.isLoading = true;
      if (this.id > 0) {
        const res = await this.axios(`/lineRich/${this.id}`);
        if (res.data.result == 1) {
          this.form = res.data.detail;
        }
      }

      this.isLoading = false;
    },
    async saveForm() {
      this.$refs.template.$v.$touch();
      this.$v.form.$touch();
      if (this.$refs.template.$v.$error || this.$v.form.$error) return;
      this.isLoading = true;
      let payload = {
        ...this.$refs.template.form,
        ...this.form,
        chatBarText: this.$refs.template.form.chatBarText,
      };
      const res = await this.axios.post(`/lineRich/CreateRichMenu`, payload);
      if (res.data.result == 1) {
        this.successAlert().then(() => this.$router.push("/setting/rich-menu"));
      } else {
        this.errorAlert(res.data.message);
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-link {
  text-decoration: underline;
}
</style>
